@import "../../header/AU/languageSelectorComp";
.footer {
	&.au {
		@mixin standardHeader {
			color: white;
			font-family: $National2Regular;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
		}
	
		.site-footer {
			background-color: $Brand-Black;
			padding:5rem 0 0 0;
			color: #fff;
			
			@media #{$mobile-only} {
				padding: 14% 20px 0% 20px !important;
				height: auto !important;
			}
			@media  #{$breakpoint-below-iphone5} {
				padding: 19% 0 0% 0% !important;
			}
			@media #{$breakpoint-iphone12-size}{
				padding: 16% 0 0% 0% !important;
			}
			@media (min-width:1024px) and (min-height: 1365px){
				height: 750px;
				}

				&.pdp{
					@media #{$breakpoint-below-tablet} {
					 &.localized{
						margin-bottom: 65px;
					}
				}
				}
			& select {
				-moz-appearance: none; /* Firefox */
				-webkit-appearance: none; /* Safari and Chrome */
				appearance: none;
			}
	
			& .row {
				margin-top: 3%;
			}
	
			& .row-mobile {
				font-family: $National2Regular;
				font-weight: 500;
				color: #fff;
	
				.global-accordian-container {
					.global-accordian-header-container {
						padding: 17px 17px;
						border-top: 1px solid $Dark-Gray;
						border-bottom: 1px solid $Dark-Gray;
	
						.global-accordian-header {
							@include standardHeader();
						}
					}
	
					.global-accordian-content {
						.footer-mobile-list {
							padding-left: 17px;
							list-style: none;
							outline: none;
						}
	
						.footer-mobile-list > li {
							padding-top: 20px;
							outline: none;
						}
	
						.footer-mobile-list > li > a {
							@include standardHeader();
							font-weight: normal;
							letter-spacing: -0.1px;
							text-decoration: unset;
							white-space: nowrap;
							outline: none;
						}
	
						@media screen and (max-width: 276px) {
							.footer-mobile-list > li > a {
								white-space: pre-wrap;
							}
						}
					}
				}
	
				.footer-accordian-open {
					background: rgba(73, 73, 73, 0.4);
					outline: none;
				}
			}
	
			& .accordion-conatiner {
				& li {
					display: block;
					@include standardHeader();
					font-weight: normal;
					letter-spacing: -0.1px;
					color: $Light-Gray;
					margin-bottom: 5px;
	
					& a {
						color: $Light-Gray;
						font-weight: normal;
						text-decoration: none;
						font-size: 14px;
						cursor: pointer;
	
						&:hover {
							color: #fff;
						}
					}
				}
			}
	
			.row-copyright {
				margin-top: 9%;
			}
	
			.pdp-row-copyright {
				margin-top: 9%;
	
				@media #{$breakpoint-below-tablet} {
					margin-top: 0;
					height: 12vh;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
	
			& .mobile-language {
				display: flex;
				// justify-content: space-between;
				justify-content: flex-end;
				margin: 6% 4%;
				align-items: center;
	
				@media screen and (max-width: 320px) {
					margin: 6% 4%;
				}
	
				& button {
					background-color: $Brand-Black;
					border: none;
					@media #{$mobile-only} {
						width: 90px;
						padding: 0;
					}
				}
			}
	
			& .mobile-horizontal {
				display: none;
			}
	
			hr {
				margin: 0 !important;
			}
	
			.app-store {
				display: block;
				line-height: 4;
				color: $Inactive-Gray;
				padding: 18px 20px 18px 20px;
				margin-bottom: 13%;
	
				& .flex {
					font-size: 14px;
				}
			}
	
			.row-one {
				display: inline-flex;
				justify-content: space-evenly;
				width: 100%;
				font-family: $National2Regular;
				font-size: 14px;
				margin-top: 2%;
				margin-bottom: 4%;
				margin-left: 0px;
				margin-right: 0px;
				@media #{$mobile-only} {
					margin-top: 0 !important;
				}
	
				& img {
					cursor: pointer;
					border-radius: 3px;
				}
	
				& .category-items {
					display: inline-flex;
					justify-content: space-evenly;
					width: 57%;
					margin-left: 6%;
					@media only screen and (min-device-width: 1021px) and (max-device-width: 1199px) {
						margin-left: 2%;
					}
					& li {
						list-style-type: none;
					}
					&.row-mobile {
						display: none;
					}
				}
	
				& .findstore {
					width: 11%;
	
					a {
						text-decoration: underline !important;
						color: #fff !important;
						font-size: 13px;
						font-weight: normal;
						cursor: pointer;
					}
	
					img {
						margin-right: 8.6%;
						@media only screen and (min-device-width: 1021px) and (max-device-width: 1199px) {
							margin-right: 7%;
						}
					}
				}
				.download-app-mobile{
					@media only screen and (min-device-width: 1200px) {
						padding-left: 30px;
					}
				}
			}
	
			.row-two {
				display: $d-inline-flex;
				justify-content: space-between;
				width: 100%;
				text-align: $center;
				margin-top: 2%;
				font-family: $National2Regular;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 22px;
				padding-bottom: 30px;
				margin-left: 0px;
				margin-right: 0px;
				& .lang-div {
					margin-left: 4%;
					@include language-selector-comp-styles($withBorder: true, $color: $Light-Gray);
	
					& button {
						background-color: $Brand-Black;
						border: none;
					}
				}
				& .lang-div-aus {
					.global-custom-select-container .customSelect {
						color: #fff;
					}
					.dropdown-img {
						display: none;
					}
					.customSelect span {
						font-family: $National2Medium;
					}
					.customSelectOption span {
						color: #202124;
						float: left;
						line-height: 48px;
						border-bottom: 1px solid #202124;
						width: 100%;
						// font-family: "National2-Medium";
					}
					.lang-selector-wrapper {
						display: flex;
						padding: 8px 20px;
						vertical-align: middle;
						border: 1px solid #fff;
						border-radius: 25px;
						
						span {
							font-size: 16px;
							margin-left: 8px;
						}
						.active-lang {
							font-weight: bold;
						}
					}
					.customSelectOption {
						min-height: auto;
						margin: 0;
						// background-color: #202124;
						float: left;
						padding: 0 23px;
					}
					.customSelectOption:hover span {
						color: #000;
					}
					.customSelectOption span:not(.active-lang) {
						padding-left: 23px;
					}
					.global-custom-select-container .global-custom-select-dropdown {
						bottom: 37px;
						background-color: #fff;
						color: #202124;
						border-radius: 8px;
						left: -35px;
						min-width: 224px;
						max-height: fit-content;
					}
					.global-custom-select-container {
						border-bottom: 0px;
					}
					.customSelectOption:last-child span {
						border: 0;
					}
					.customSelectOption span.active-lang::before {
						content: "";
						width: 14px;
						height: 11px;
						background-image: url("../../../staticAssets/images/lang-tick-black.svg");
						margin: 0 8px 0px 0;
						display: inline-block;
					 }
					 .lang-selector {
						padding: 14px 22px;
						display: inline-block;
						pointer-events: none;
					}
					// visibility: hidden;
					@media #{$breakpoint-above-tablet} {
						width: 118px;
					}
					@media #{$breakpoint-between-ipad-tablet} { 
						margin: 0 auto;
    				padding-bottom: 12%;
					}
					@media #{$mobile-only} {
						// display: none;
						position: absolute;
						bottom: 27%;
						left: 50%;
						margin-left: -58px;

						&.disposition-set {
							bottom: 23%;
						}
					}
				}
	
				& .social-div {
					margin-right: 1.4%;
				}
			}
	
			.social-icons {
				text-align: $right;
				margin-top: 0;
				margin-bottom: 0 !important;
				li {
					.support-links-menu {
						padding-right: unset;
					}
				}
				@media #{$mobile-only} {
					display: flex;
					margin: auto;
					flex-wrap: wrap;
					max-width: unset;
					li {
						width: 50px;
						margin: auto 5px 5% 5px;
						.support-links-menu {
							padding: unset;
						}
					}
				}
				@media #{$breakpoint-below-iphoneX} {
					display: flex;
					margin: auto;
					flex-wrap: wrap;
					li {
						width: 45px;
						margin: auto 2px 5% 2px;
						.support-links-menu {
							padding: unset;
						}
					}
				}
			}
	
			& .language-select {
				border-radius: 34px;
				padding: 2% 5% 3% 23%;
				background-color: $Brand-Black;
				font-family: $National2Regular;
				color: $white;
				border: 1px solid $Dark-Gray;
				box-sizing: border-box;
				width: 89px;
				height: 36px;
				cursor: pointer;
				text-transform: uppercase;
				pointer-events: none;
	
				@media #{$breakpoint-below-tablet} {
					margin-top: 0;
				}
	
				@media (max-width: 480px) {
					padding: 1% 7% 2% 28px;
					min-width: 90px;
				}
			}
	
			& .dropdown-img-footer {
				background: url("../../../staticAssets/images/down-arrow.svg") no-repeat 100% 100%;
				height: 15px;
				width: 12px;
				position: relative;
				margin-top: 4%;
				right: 27%;
				display: table;
	
				@media #{$mobile-only} {
					margin-top: 5%;
					right: 56% !important;
				}
	
				@media #{$breakpoint-below-tablet} {
					right: 36%;
				}
			}
	
			.social-btns {
				display: flex;
				align-items: flex-start;
				justify-content: center;
				column-gap: 5%;
	
				@media #{$mobile-only} {
					column-gap: unset;
				}
	
				& img {
					width: 100%;
					height: 100%;
					cursor: pointer;
				}
	
				a:nth-of-type(2) {
					margin-left: 5%;
				}
			}
			.download-app {
				@media #{$breakpoint-above-tablet} {
					display: none;
				}
			}
			.social-btns-aus {
				// height: 60px;
				a:nth-of-type(1) {
					display: block;
					margin-bottom: 20px;
				}

				& img {
					height: 45px;
					width: 133px;
				}

				@media #{$breakpoint-tablet-only} {
					display: flex;
					flex-direction: row;
					& .link {
						width: fit-content;
						margin-left: auto;
						margin-right: auto;
					}
				}
				@media #{$mobile-only} {
					display: flex;
					width: 100%;
					align-items: flex-start;
					justify-content: space-between;
	
					& img {
						width: 100%;
						height: 100%;
						cursor: pointer;
					}
	
					a:nth-of-type(2) {
						margin-left: 5%;
					}
				}
			}
	
			.flex {
				margin-left: 0;
				margin-bottom: 0;
			}
	
			.app {
				&-btn {
					width: 45%;
					max-width: 160px;
					color: #fff;
					margin: 20px 10px;
					text-align: left;
					border-radius: 5px;
					text-decoration: none;
					font-family: "Lucida Grande", sans-serif;
					font-size: 10px;
					text-transform: uppercase;
					border: 1.44323px solid #494949;
	
					&.blu {
						background-color: #101010;
						transition: background-color 0.25s linear;
	
						&:hover {
							background-color: #454545;
						}
					}
	
					img {
						text-align: center;
						font-size: 28px;
						margin-right: 7px;
					}
				}
			}
		}
	
		.header-text-footer {
			font-family: $National2Medium !important;
			@include standardHeader();
			margin-bottom: 10px;
		}
		.footer-links {
			padding-left: 0;
			margin-top: 0;
			list-style: none;
			width: 130px;
			text-align: left;
	
			& li {
				display: block;
				@include standardHeader();
				font-weight: normal;
				letter-spacing: -0.1px;
				color: $Light-Gray;
				margin-top: 5px;
	
				& a {
					padding-right: 0%;
					color: $Light-Gray;
					font-weight: 400;
					text-decoration: none;
					font-size: 14px;
					line-height: 24px;
					cursor: pointer;
					white-space: normal;
	
					&:hover {
						color: #fff;
					}
					&:focus {
						outline: none;
					}
				}
			}
	
			& .kfc-footer-logo {
				height: 60px;
				width: 60px;
			}
		}
	
		.footer-links.inline {
			li {
				display: inline-block;
			}
		}
	
		.copyright-mobile {
			margin: auto;
			// padding-bottom: 7%;
		}
	
		.column {
			display: flex;
			align-items: center;
	
			.footer-linkview {
				margin-bottom: 5px;
				text-align: left;
				padding: 0 8px 0 0;
				font-weight: 400;
				@media only screen and (min-device-width: 320px) and (max-device-width: 380px) {
					padding: 0 10px 0px 10px;
				}
				opacity: 0.9;
				// margin-left:-11px;
				li {
					display: inline-block;
					a {
						font-style: normal;
						font-weight: normal;
						font-size: 12px;
						@media #{$breakpoint-below-iphone5}{
							font-size: 10px !important;
						}
						line-height: 22px;
						color: $Light-Gray;
						text-decoration: none;
						&::before {
							content: ".";
							font-size: 30px;
							padding: 0px 3px 0 3px;
							cursor: default;
	
							@media #{$breakpoint-below-include-iphone5SE} {
								font-size: 22px;
								padding: 0px 2px 0 2px;
							}
						}
					}
					&:first-child {
						a::before {
							content: "";
						}
					}
				}
			}
	
			.copyright-text {
				margin: 0;
				font-family: $National2Regular;
				font-size: 12px;
				text-align: $center;
				opacity: 0.9;
				line-height: 12px;
				color: $Inactive-Gray;
				font-weight: 400;
			}
		}
	
		.social-icons {
			padding-left: 0;
			margin-bottom: 0;
			list-style: none;
			text-align: center;
	
			li {
				display: inline-block;
				margin-bottom: 4px;
				margin-left: 10px;
	
				& a:focus {
					outline: none;
				}
	
				img {
					cursor: pointer;

					@media (min-width: 1023px) {
						height: 30px;
						width: 30px;
					}
					
				
					@media #{$breakpoint-below-tablet} {
						height: 50px;
						width: 50px;
					}
	
					@media #{$breakpoint-below-include-iphone5} {
						height: 40px;
						width: 40px;
					}
				}
	
				li.title {
					margin-right: 15px;
					text-transform: uppercase;
					color: #96a2b2;
					font-weight: 700;
					font-size: 13px;
				}
			}
	
			.social-icons.size-sm {
				a {
					line-height: 34px;
					height: 34px;
					width: 34px;
					font-size: 14px;
				}
			}
		}
	
		/**  Mobile Styles **/
		.accordion {
			font-family: $National2Regular;
			background-color: $Brand-Black;
			color: $white;
			cursor: pointer;
			padding: 18px;
			width: 100%;
			border: none;
			text-align: $left;
			outline: none;
			font-size: 14px;
			transition: 0.4s;
			border-top: 1px solid $Dark-Gray !important;
	
			&:hover {
				background-color: #ccc;
			}
	
			&::after {
				content: url("../../../staticAssets/images/SingleArrow.svg");
				color: $white;
				font-weight: bold;
				float: right;
				margin-left: 5px;
				@media #{$breakpoint-above-medium-tablet} {
					padding-top: 10px;
				}
				@media #{$mobile-only} {
					padding-top: 10px;
				}
			}
		}
	
		// .active {
		// 	background-color: #ccc;
	
		// 	&::after {
		// 		content: "\2303";
		// 	}
		// }
	
		.panel {
			padding: 0 18px;
			background-color: white;
			max-height: 0;
			overflow: hidden;
			transition: max-height 0.2s ease-out;
		}
		.footerIcon {
			padding-left: 5px;
		}
		.hideFooterIcon {
			display: none;
		}
	
		@media #{$breakpoint-below-tablet} {
			.site-footer {
				background-color: $Brand-Black;
				padding: 5% 5% 5% 5%;
				font-size: 15px;
				line-height: 24px;
				color: #fff;
				height: auto;
	
				.row-one {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: unset;
					font-family: $National2Regular;
					font-size: 14px;
					margin-top: 2%;
					margin-bottom: 4%;
					padding-left: 5.2%;
					padding-right: 5.2%;
	
					& .mobile-header-logo {
						display: flex;
						justify-content: center;
						align-items: flex-start;
						margin-top: 10%;
						margin-bottom: 14%;
					}
					& img {
						cursor: pointer;
					}
	
					& .category-items {
						display: none;
						width: 100%;
						justify-content: space-evenly;
						margin-left: 2%;
	
						& li {
							list-style-type: none;
						}
						&.row-mobile {
							display: block;
							margin-left: 0;
						}
					}
	
					& .findstore {
						padding: 10px 18px;
						width: 100%;
						margin-top: 3%;
						margin-bottom: 3%;
	
						& img {
							margin-right: 3%;
							@media #{$breakpoint-tablet-only} {
								margin-right: 1.5%;
								margin-top: -4px;
							}
						}
	
						& a {
							color: $white;
							font-weight: normal;
							text-decoration: none !important;
							font-size: 14px;
						}
					}
	
					& .row-mobile {
						font-family: $National2Regular;
						font-weight: 500;
						color: #fff;
	
						.global-accordian-container {
							.global-accordian-header-container {
								padding: 17px 17px;
								border-top: 1px solid $Dark-Gray;
								border-bottom: 1px solid $Dark-Gray;
	
								.global-accordian-header {
									@include standardHeader();
								}
							}
	
							.global-accordian-content {
								.footer-mobile-list {
									padding-left: 17px;
									list-style: none;
									outline: none;
								}
	
								.footer-mobile-list > li {
									padding-top: 20px;
									outline: none;
								}
	
								.footer-mobile-list > li > a {
									@include standardHeader();
									font-weight: normal;
									letter-spacing: -0.1px;
									text-decoration: unset;
									white-space: nowrap;
									outline: none;
								}
	
								@media screen and (max-width: 276px) {
									.footer-mobile-list > li > a {
										white-space: pre-wrap;
									}
								}
							}
						}
	
						.footer-accordian-open {
							background: rgba(73, 73, 73, 0.4);
							outline: none;
						}
					}
	
					& .mobile-horizontal {
						border-top: 1px solid $Dark-Gray;
						display: block;
						width: 100%;
					}
	
					& .download-app-mobile {
						display: flex;
						align-self: flex-start;
						line-height: 4;
						color: #dbdbdb;
						padding: 18px 10% 10px 10%;
						flex-direction: column;
						width: 100%;
	
						@media #{$breakpoint-tablet-only} {
							padding: 18px 25% 10px 25%;
							.download-app {
								position: relative;
								left: 18px;
							}
						}
						@media #{$breakpoint-iphone-six-plus} {
							@media (orientation: landscape) {
								padding: 18px 23% 10px 22%;
							}
						}
						@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
							padding: 18px 20% 10px 21%;
						}
						@media #{$breakpoint-below-iphone5} {
							padding:18px 0% 10px 18px !important
						}
					}
				}
	
				.row-two {
					display: flex;
					flex-direction: column-reverse;
					width: unset;
	
					& .copyright-mobile {
						align-self: center;
						margin-top: 29px;
						@media #{$mobile-only} {
							margin-top: 25%;
						}
						@media #{$medium-only} {
							padding-bottom: 60px;
						}
						@media #{$breakpoint-between-mob-tablet} {
								padding-bottom: 60px;
						}
						&.non-localized{
							@media #{$mobile-only} {
								padding-bottom: 90px;
								margin-top: 85px;
							}
							@media #{$breakpoint-above-destop-low-height} {
								padding-bottom: 90px;
							}
						}
						&.localized{
							@media #{$mobile-only} {
								padding-bottom: auto !important;
							}
							@media #{$breakpoint-above-destop-low-height} {
								padding-bottom:60px !important;
							}
						}
					}
	
					& .social-div-mobile {
						align-self: center;
						width: 100%;
						@media #{$breakpoint-tablet-only} {
							width: fit-content;
						}
					}
	
					& .lang-div {
						margin-top: -86px;
						@include language-selector-comp-styles($withBorder: true, $color: $Light-Gray);
						.language-selector-container {
							margin-left: unset;
						}
					}
				}
			}
		}
	
		.row-oneaus {
			.header-logo-footer.mobile-header-logo {
				margin-top: 0.2%;

				& .header-logo img {
					height: 60px;
					width: 60px;
				}
			}
			
			@media #{$mobile-only} {
				margin-bottom: 0 !important;
				.row-one {
					margin-top: 0 !important;
				}
				.global-accordian-container .global-accordian-header-container .global-accordian-arrow {
					width: 12px;
					height: 12px;
				}
				.mobile-header-logo {
					margin-bottom: 19% !important;
				}
				// .row-mobile .global-accordian-container .global-accordian-header-container{
				// 		padding: 12px 24px 17px 17px !important;
	
				// }
				.social-btns-aus a:nth-of-type(1) {
					width: 141px;
					margin-bottom: 0px;
					margin-left: 0.3%;
				}
				.social-btns-aus img:nth-of-type(1) {
					width: 141px;
					height: 48px;
					border-radius: 3px;
				}
				.social-btns-aus a:nth-of-type(2) {
					margin-left: 6%;
				}
				.download-app-mobile {
					padding: 30px 17px 10px 18px !important;
				}
				.download-app {
					line-height: 24px;
					margin-bottom: 15px;
				}
				.social-btns-aus {
					height: 63px;
					align-items: center;
    				justify-content: flex-start;
						@media #{$breakpoint-iphone13} {
							justify-content: space-between;
						}
				}
				.header-logo-footer.mobile-header-logo {
					margin-top: 0;
				}
			}
			@media only screen and (max-device-width: 360px) {
				.social-btns-aus a img:nth-of-type(1) {
					width: 120px;
				}
			}
			
		}
		.row-twoaus {
			padding-bottom: 25px;
			@media only screen and (min-device-width: 1800px) and (max-device-width: 2560px) {
				margin-left: -32px !important;
			}
			.column .footer-linkview li a {
				color: $Inactive-Gray;
			}
			// margin-top: 5.2% !important;
			.social-icons {
				margin-bottom: 0.8rem;
			}
			@media #{$breakpoint-below-iphone5}{
				.social-icons li {
					margin: auto 3px 0% 2px !important;
				}
			}
			@media #{$mobile-only} {
				.social-icons li {
					margin: auto 5px 0% 0px;
				}
				.social-icons li:last-child{
					margin-right: 0px;
				}
				.social-icons li img {
					height: 45px;
					width: 45px;
				}
				.footer-linkview {
					margin-bottom: 10px;
					padding: 0 8px 0 8px;
					text-align: center;
				}
				.copyright-mobile {
					margin: auto;
					margin-left: 0;
				}
				.copyright-text {
					width: 287px;
					line-height: 22px;
					margin: auto;
				}
				.footer-linkview li a {
					font-weight: 400;
					color: $Inactive-Gray;
				}
			}
		}
	}
}
.tooltip.show .tooltip-inner {
	background-color: #202124 !important;
}
.tooltip.show.bs-tooltip-left .arrow::before {
	border-left-color: #202124 !important;
}
